.hero {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) center
      bottom / cover,
    url("../../Assets/homepagebanner-min.webp");
  background-size: cover;
  background-position: center;
  height: 760px;
  .container,
  .row {
    position: relative;
    height: 100%;
    color: #fff;
    h1 {
      font-size: 65px;
    }
    h4 {
      font-size: 35px;
      font-weight: 400 !important;
    }
  }
  // video{
  //     width:100%;
  //     height:750px;
  //     object-fit:cover;
  // }
  .btn2 {
    position: absolute;
    z-index: 8;
    right: 2%;
    bottom: 5%;
    font-size: 18px;
    a {
      color: #fff;
      text-decoration: none;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &:hover {
      background-color: #8ed61d;
      transition: 300ms ease-in-out;
    }
  }
}

@media (max-width: 1024px) {
  .hero {
    video {
      height: 450px !important;
    }
    .btn2 {
      display: none;
    }

    .container,
    .row {
      position: relative;
      height: 100%;
      color: #fff;
      h1 {
        font-size: 54px;
      }
      h4 {
        font-size: 26px;
        font-weight: 400 !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .hero {
   

    .container,
    .row {
      position: relative;
      height: 100%;
      color: #fff;
      h1 {
        font-size: 40px;
      }
      h4 {
        font-size: 20px;
        font-weight: 400 !important;
      }
    }
  }
}
@media (max-width: 470px) {
    .hero {
     
  
      .container,
      .row {
        position: relative;
        height: 100%;
        color: #fff;
        h1 {
          font-size: 30px;
        }
        h4 {
          font-size: 16px;
          font-weight: 400 !important;
        }
      }
    }
  }
  
