body,
html {
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

p {
  font-size: 17px;
}

.container {
  max-width: 90% !important;
}

@media (min-width:1550px) {
  .container {
    max-width: 1320px !important;
  }
}

.btn {
  padding: 6px 30px !important;
  background: #8ed61d !important;
  color: #fff !important;
  /* border-radius: 50px!important; */
  font-size: 18px !important;
 
}

.btn:hover {
  background-color: black !important; 
  color: #fff;

}



.btn2 {
  background: #8ed61d;
  padding: 10px 30px;
  /* background: transparent; */
  border: 2px solid #8ed61d;
  color: #000;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    position: relative;
    left: 0px;
    transition: 400ms ease-in-out;
  }

  &:hover {
    background-color: black;
    color: #fff;

    svg {
      left: 10px;
      transition: 200ms ease-in-out;

      path {
        fill: #fff;
      }
    }
  }
}

.btn2:hover {
  border: 2px solid black;
  background-color: black !important;
  color: #fff;
}


.btn2 a:hover {
  color: #fff !important;
}

.btn3 {
  background: #8ed61d;
  padding: 10px 30px;
  /* background: transparent; */
  border: 2px solid #8ed61d;
  color: #fff;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    position: relative;
    left: 0px;
    transition: 400ms ease-in-out;
  }

  &:hover {
    background-color: black;
    color: #fff;

    svg {
      left: 10px;
      transition: 200ms ease-in-out;

      path {
        fill: #fff;
      }
    }
  }
  /* padding: 10px 30px;
  background: #8ed61d;
  color: #000;
  border: 1px solid #8ed61d;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 17px;

  svg {
    position: relative;
    left: 0px;
    transition: 400ms ease-in-out;

    path {
      fill: #000;
    }
  }

  &:hover {
    background-color: transparent;
    border: 2px solid #8ed61d;
    color: #fff;

    svg {
      left: 10px;
      transition: 200ms ease-in-out;

      path {
        fill: #fff;
      }
    }
  } */
}

.btn3:hover {
  border: 2px solid black;
  background-color: black !important;
  color: #fff !important;
}


.btn3 a:hover {
  color: #fff !important;
}

.btn4 {
  padding: 10px 30px;
  background: #8ed61d;
  color: #fff;
  border: 1px solid #8ed61d;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 15px;

  svg {
    position: relative;
    left: 0px;
    transition: 400ms ease-in-out;

    path {
      fill: transparent;
    }
  }

  &:hover {
    background-color: transparent;
    border: 2px solid #8ed61d;
    color: #8ed61d;

    svg {
      left: 10px;
      transition: 200ms ease-in-out;

      path {
        stroke: #8ed61d;
      }
    }
  }
}