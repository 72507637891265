.currentprojectbtn {
  background: #d61d1d;
  color: #fff;
  border: none;
  border-radius: 60px;
  padding: 10px 25px;
  position: fixed;
  bottom: 10%;
  right: 2%;
  z-index: 9999;
  transition: 0.4s;

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background: #fff;
    scale: 1.1;
    transition: 0.8s;

    a {
      color: #d61d1d;
    }

    box-shadow: 0 0 10px 10px #00000015;

    svg {
      path {
        stroke: #d61d1d;
      }
    }
  }
}
